@import "../../variable/variable.scss";
.form {
  width: 690px;
  height: 620px;
  background: url("../../img/formPc.png") 0 0/100% 100% no-repeat transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 90px;
  
  color: rgba(255, 255, 255, 0.567);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  gap: 10px;
  @media (max-width:$mobile){
  background: url("../../img/formMob.png") 0 0/100% 100% no-repeat transparent;
      width: 331px;
      height: 520px;
      padding: 30px;
      padding: 3px 30px 30px 30px ;
      gap: 9px;
  }
  &__input {
    display: flex;
    flex-direction: column;
    gap: 5px;

    input {
     
      height: 50px;
      background: rgba(255, 255, 255, 0.05);
      color: white;
      font-size: 21px;
      padding-left: toRem(20);
      border-radius: 11px;
      @media (max-width:$mobile){
         height: 43px; 
      }
      :focus{
        outline: 1px solid $btnColor;
      }
      
    }
    label {
      font-weight: 400;
      font-size: 13px;
      line-height: 138%;
    }
  }
  &__title {
    text-align: left;
    @include adaptiveValue("font-size", 50, 26);
    margin-bottom: toRem(10);
    margin-top: toRem(70);@media (max-width:$mobile){
       font-size: 25px; 
    }
    span {
      color: $btnColor;
    }
  }
  &__button{
    max-width: max-content;
    display: flex; margin: 0 auto;
    padding: 5px 10px;
    margin-top: toRem(10);
  }
}
input:focus{
        outline: 1px solid $btnColor;
      }