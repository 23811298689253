@import "/src/variable/variable.scss";

body {
  position: relative;
  z-index: 0;
}

.root {
  z-index: -1;
  position: relative;
  background-color: #0b0c0f;
  @media (min-width: $mobile) {
    // background: url("../src/img/bgG.png") 0px 0px /100% 100% no-repeat;
    background-color: #0b0c0f;
  }
}
// HEADER
.header {
  max-width: 1396px;
  // overflow: hidden;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 47;
  padding: toRem(15) 0;
  &__wraper {
    max-width: $maxWidth;
    padding: 0 toRem(15);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: toRem(10);
  }
  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: $mobile) {
      transform: translateX(400px);
      position: absolute;
      top: 0;
      right: 0;
      z-index: 44;
      height: 100vh;
      background: $bgColor;
      display: flex;
      transition: all 0.7s;
      &.active {
        transform: translateX(0px);
      }
    }
  }

  &__buttons {
    order: 2;
    text-wrap: nowrap;
  }
}
.burger {
  display: none;
  @media (max-width: $mobile) {
    display: block;
    position: relative;
    color: red;
    z-index: 55;
    min-width: toRem(25);
    min-height: toRem(25);

    &::before {
      content: "";
      height: 3px;
      width: 30px;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      background: rgb(255, 255, 255);
      transition: all 0.4s;
    }
    &::after {
      content: "";
      height: 3px;
      width: 30px;
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      background: rgb(255, 255, 255);
      transition: all 0.4s;
    }
    span {
      &::before {
        content: "";
        height: 3px;
        width: 20px;
        background: #fcfcfc;
        top: 45%;
        left: 10px;
        position: absolute;
        z-index: 77;
        transition: all 0.4s;
      }
    }
    &__active {
      &::before {
        content: "";
        transform-origin: left;
        rotate: 48deg;
      }
      &::after {
        content: "";
        transform-origin: left;
        rotate: -45deg;
      }
      span {
        &::before {
          content: "";
          left: 100px;
        }
      }
    }
  }
}
.logo {
  max-width: toRem(170);
  max-height: toRem(33);
  @include adaptiveValue("width", 223, 170);
  @include adaptiveValue("height", 44, 33);
  img {
    width: 100%;
    height: 100%;
  }
}
.buttons-header {
  display: flex;
  gap: toRem(25);
  &__btn-transparent {
    font-weight: 300;
    font-size: toRem(14);
    line-height: 171%;
    color: #ffffff;
    opacity: 0.5;
    @media (max-width: $mobile) {
      opacity: 0.8;
    }
  }

  &__btn {
  }
}
.menu {
  &__list {
    align-items: center;
    display: flex;
    column-gap: toRem(24);
    @include adaptiveValue("column-gap", 30, 22);
    flex-wrap: wrap;
    @media (max-width: $mobile) {
      order: 1;
      flex-direction: column;
      gap: toRem(25);
      align-items: center;
      justify-content: center;
      padding: 40px 80px;
    }
  }


  &__item {
  }

  &__link {
    font-weight: 300;
    font-size: toRem(14);
    line-height: 171%;
    color: #ffffff;
    opacity: 0.5;
    transition: all 0.4 ease;
    @media (min-width: $mobile) {
      &:hover {
        color: $hoverColor;
        scale: 1.1;
      }
    }
    @media (max-width: $mobile) {
      opacity: 0.8;
    }
  }
}

// MAIN
.main {
  @include adaptiveValue("padding-top", 190, 80);
  @include adaptiveValue("padding-bottom", 130, 80);
  position: relative;
  z-index: 4;
  &::before{
    content:'';
    background: url('./img/dec1.png') 0 0/100% 100% no-repeat;
    @include adaptiveValue("width",214 ,82 );
    @include adaptiveValue("height",239 , 92);
    position: absolute;
    top: 0;
    left: 0;
  }
  &::after{
    content:'';
    background: url('./img/dec2.png') 0 0/100% 100% no-repeat;
    @include adaptiveValue("width",313,183 );
    @include adaptiveValue("height", 350, 205);
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &__img{
     @include adaptiveValue("width",530 ,325 );
     @include adaptiveValue("height",530 ,325 );
     transform: translate(0px,-100px);
     img{
      width: 100%;
      height: 100%;
     }
  }
  &__wraper {
    display: flex;
    @media (max-width:$tablet){
      flex-direction: column;
    }
    align-items: center;
    justify-content: space-between;
    max-width: 1396px;
    // overflow: hidden;
    margin: 0 auto;
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 0 toRem(15);
    position: relative;
    &::before {
      content: "";
      transform: rotate(270deg);
      right: 0px;
      top: -110%;
      z-index: -1;
      position: absolute;
      width: 500px;
      height: 500px;
      background: url("../src/img/Blurbg.png") 0 0 /100% 100% no-repeat;
    }
  }
  &__title {
    font-weight: 700;

    @include adaptiveValue("font-size", 80, 35);
    color: #ffffff;
    position: relative;
    @media (min-width: $mobile) {
      &::before {
        content: "";
        background: url("./img/Blur.svg") 0 0/100% 100% no-repeat;

        position: absolute;
        width: 677px;
        height: 604px;
        top: 50px;
        right: 0;
      }
    }
    // &::after{
    //   content:'';
    //   background: url('./img/mainLogoPc.png ') 0 0/100% 100% no-repeat;
    //   @include adaptiveValue("width",530 ,291 );
    //   @include adaptiveValue("height",563 ,325 );
    //   position: absolute;
    //   right: 0;
    //   @include adaptiveValue("right",0,40 );
    //   @include adaptiveValue("top",0 ,300 );
    //   z-index: -1;
    // }

    span {
      color: $btnColor;
    }
  }

  &__sup-title {
    margin-bottom: toRem(25);
    @include adaptiveValue("font-size", 60, 17);
    color: white;
    font-weight: 400;
  }

  &__text {
    max-width: toRem(650);
    font-weight: 300;

    @include adaptiveValue("font-size", 20, 15);
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: toRem(40);
  }

  &__buttons {
    display: flex;
    position: relative;
    gap: toRem(27);
    @include adaptiveValue("gap", 27, 15);
    font-weight: 700;
    @include adaptiveValue("padding-bottom", 200, 200);
    &::before{
      content:'';
      background: url('./img/dec3.png') 0 0/100% 100% no-repeat;
    @include adaptiveValue("width",188,112 );
    @include adaptiveValue("height", 210, 125);
    position: absolute;
    top: -130px;
    right: 130px;
    @include adaptiveValue("right",130 , -80);
    z-index: -1;
    }
  }
  .main {
    &__block {
    }
  }
  .block-main {
    position: relative;
    //  max-width: 1396px;

    margin: 0 auto;
    max-width: toRem(1919);

    &::before {
      content: "";

      @include adaptiveValue("height", 230, 550);
      background: rgba(0 0 0 / 0.3);
      position: absolute;
      top: 70px;
      z-index: 0;
      min-width: 4000px;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      filter: blur(20px);
    }
    &__sup-title {
      font-weight: 600;
      color: #ffffff;
      @include adaptiveValue("font-size", 35, 25);
      text-align: center;

      position: relative;
    }

    &__title {
      text-transform: uppercase;
      @include adaptiveValue("font-size", 35, 25);
      text-align: center;
      color: $btnColor;
      font-weight: 600;
      position: relative;
    }

    &__text {
      text-align: center;
      position: relative;
    }
  }

  .sub-title {
  }
}
.not-visible {
  opacity: 0;
  transition: all 0.5s;
}
.visible {
  opacity: 1;
  transition: all 0.5s;
}
.buttons-main {
  &__batton-transparent {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: toRem(5);
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    @include adaptiveValue("font-size", 20, 18);
    border: 0.3px solid rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    padding: 13px 24px;
    transition: all 0.5s ease;
    width: 215px;
    @media (max-width: $mobileSmall) {
      padding: 13px 16px;
      width: max-content;
    }
    img {
      margin-bottom: toRem(-4);
    }
  }
  &__batton {
    width: 233px;
    @media (max-width:$mobileSmall){
       width: max-content; 
    }
  }
}
// WORK
.work {
  @include adaptiveValue("padding-bottom", 118, 60);
position: relative;
&::before{
      content:'';
      background: url('./img/dec3.png') 0 0/100% 100% no-repeat;
    @include adaptiveValue("width",201,124);
    @include adaptiveValue("height", 225, 138);
    position: absolute;
    top: -200px;
    @include adaptiveValue("top",-200 ,0 );
   left: 0;

    z-index: -1;
    }
    &::after{
      content:'';
      background: url('./img/dec3.png') 0 0/100% 100% no-repeat;
    @include adaptiveValue("width",415,208);
    @include adaptiveValue("height", 404, 233);
    position: absolute;
    top: 40%;
    
   left: -140px;

    }
  &__wraper {
    position: relative;
    max-width: 1024px;
    padding: 0 toRem(15);
    margin: 0 auto;
  }
  &__title {
    margin-bottom: toRem(25);
  }

  &__sup-title {
    text-align: center;
    color: #ffffff;
    opacity: 0.5;
    @include adaptiveValue("font-size", 20, 15);
    line-height: 175%;
    font-weight: 300;
    @include adaptiveValue("margin-bottom", 130, 40);
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: toRem(40);
    // row-gap: 70px;

    @media (min-width: 608px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 1fr);
      grid-column-gap: 50px;
      grid-row-gap: 35px;
    }
  }

  &__item {
    @include adaptiveValue("max-width", 840, 310);
    @media (min-width: 608px) {
      max-height: toRem(161);
      max-width: toRem(410);
      &:nth-child(even) {
        margin-left: auto;
      }
      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
      }
      &:nth-child(2) {
        grid-area: 2 / 2 / 3 / 3;
      }
      &:nth-child(3) {
        grid-area: 3 / 1 / 4 / 2;
      }
      &:nth-child(4) {
        grid-area: 4 / 2 / 5 / 3;
      }
      &:nth-child(5) {
        grid-area: 5 / 1 / 6 / 2;
      }
      &:nth-child(6) {
        grid-area: 6 / 2 / 7 / 3;
      }
    }
  }
}

.progres-bar {
  position: absolute;

  top: 30%;
  left: 50%;
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-between;
  z-index: 2;
  width: 0.5px;
  background: rgb(194, 191, 191);

  @media (max-width: 608px) {
    left: 94%;
    height: 75%;
    top: 20%;
  }
  &__num {
    transform: translateX(-3px);
    position: relative;
    z-index: 3;
    opacity: 1;
    color: #000;
    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      width: toRem(40);
      height: toRem(40);
      background: rgb(194, 191, 191);
      border-radius: 50%;
      top: -11px;
      left: -16px;
      transition: all 0.5s ease 1s;
    }
  }
}

.progres-bar__num.active::before {
  background: rgb(93, 216, 255);
}
.wan {
  position: absolute;
  background: rgb(93, 216, 255);
  height: 0;
  width: 2px;
  left: 3px;
  top: 28px;
  z-index: 1;
  transition: all 1s ease 1.5s;
}
.wan.active {
  height: 380px;
  @media (max-width: 608px) {
   
    @include adaptiveValue("height", 400, 465  );
  }
}
.two {
  position: absolute;
  background: rgb(93, 216, 255);
  height: 0;
  width: 2px;
  left: 3px;
  top: 28px;
  z-index: 1;
  transition: all 1s ease 1.5s;
}
.two.active {
  height: 380px;
  @media (max-width: 608px) {
    
    @include adaptiveValue("height", 400, 465);
  }
}

// FEAURES
.features {
  @include adaptiveValue("padding-bottom", 130, 50);
  max-width: 1396px;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  &__container {
    overflow: visible;
  }

  &__title {
    margin-bottom: toRem(119);
  }

  .slide {
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: toRem(20);
    height: 315px;

    @include adaptiveValue("padding", 40, 15);
    max-width: 455px;
    transition: all 0.5s;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: toRem(10);
    }
    @media (min-width: $mobile) {
      &:hover {
        scale: 1.1;
        box-shadow: 0.2px -0.5px 0px $btnColor;
      }
    }
    &__text {
    }
  }
  .header-slide {
    &__img {
      // width: toRem(74) !important;
      // height: toRem(60) !important;

      img {
      }
    }

    &__title {
    }
  }
}

// FAG!!
.faq {
  position: relative;
   &::after{
      content:'';
      background: url('./img/dec3.png') 0 0/100% 100% no-repeat;
    @include adaptiveValue("width",415,208);
    @include adaptiveValue("height", 404, 233);
    position: absolute;
    top: -100px;
    
   left: 0px;

    
    }
  &__wraper {
    margin: 0 auto;
    max-width: 1330px;
    padding: 0 toRem(15);
  }

  &__title {
    margin-bottom: toRem(75);
  }
  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__item {
  }
}
.item-faq {
  @include adaptiveValue("padding-left", 100, 45);
  min-height: 70px;
position: relative;
z-index: 1;

  // margin-bottom: 20px;

  &__active {
    // @include adaptiveValue("height", 180, 180);
    // @media (max-width: 534px) {
    //   @include adaptiveValue("height", 230, 260);
    //   min-height: max-content;
    // }
    // max-height: max-content;
  }
  &__title {
    font-weight: 600;
    color: #ffffff;
    @include adaptiveValue("font-size", 20, 18);
    line-height: 120%;
    // margin-bottom: toRem(18);

    span {
      position: absolute;
      left: toRem(0);
      transform: translateY(5px);
      color: $liColor;

      @include adaptiveValue("font-size", 30, 25);
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  &__text {
    font-weight: 400;
    margin-bottom: toRem(10);
    @include adaptiveValue("font-size", 17, 16);
    color: #ffffff;
    opacity: 0.5;
    line-height: 140%;
    padding-bottom: 10px;
  }

  &__button {
    margin-left: auto;
    padding-right: toRem(40);
    width: toRem(40);
    height: toRem(40);

    transform: translateY(0px);
    &::before {
      content: "";
      position: absolute;
      background: $btnColor;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      z-index: -1;
      top: 0px;
      left: -0px;
      transition: all 0.5s ease;
    }
    span {
      &::before {
        content: "";
        position: absolute;
        width: 15px;
        height: 3px;
        background: #000;
        top: 18px;
        left: 12px;
        transition: all 0.5s ease;
      }
      &::after {
        content: "";
        position: absolute;
        height: 15px;
        width: 3px;
        background: #000;
        top: 12px;
        left: 18px;
        transition: all 0.5s ease;
      }
    }
  }
  &__button-active {
    &::before {
      content: "";
      background: #ffffff;
      opacity: 0.2;
    }
    span {
      &::before {
        content: "";
        background: $btnColor;
        transform: rotate(50deg);
      }
      &::after {
        content: "";
        background: $btnColor;
        transform: rotate(40deg);
      }
    }
  }
}
// FOOTER
.footer {
  background: transparent;
  padding-top: toRem(82);
  padding-bottom: toRem(58);

  &__desing-author {
    display: flex;
    align-items: center;
    gap: toRem(10);
    color: #837e7e;
    justify-content: flex-end;
    margin-top: toRem(-25);
    @media (max-width: $mobile) {
      justify-content: center;
      padding-top: toRem(5);
      margin-top: 0;
    }
    @media (min-width: 1110px) {
      margin-top: toRem(-60);
    }
  }
  &__wraper {
    max-width: toRem(1396);
    padding: 0 toRem(15);
    justify-content: space-between;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    gap: toRem(35);
    @media (max-width: $mobile) {
      gap: toRem(0);
    }
  }
  &__item {
    color: #ffffff;

    a {
      opacity: 1;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: toRem(20);
    @media (max-width: $mobile) {
      justify-content: space-around;
    }
  }

  &__policy {
  }
}

.body-footer {
  &__social {
    display: flex;
    align-items: center;
    gap: toRem(20);
    align-self: center;
  }
}

.logo {
}
.social-footer {
  img {
    width: 30px !important;
  }
  &__discord {
  }

  &__telegram {
  }

  &__twitter {
    width: 30px !important;
    img {
      width: 100%;
    }
  }
}
.policy-footer {
  display: flex;
  color: #ffffff !important;
  opacity: 0.5;
  flex-wrap: wrap;
  justify-content: center;
  gap: toRem(20);
  span {
    color: #ffffff;
  }
}

/////// загальні стилі
.title {
  color: #ffffff;
  font-weight: 700;

  @include adaptiveValue("font-size", 60, 28);
  text-align: center;

  span {
    color: $btnColor;
  }
}
.title-medium {
  font-weight: 700;
  margin-bottom: toRem(60);
  margin-top: toRem(90);
  @include adaptiveValue("font-size", 40, 22);
  color: #ffffff;
}
.title-mini {
  font-weight: 700;
  @include adaptiveValue("font-size", 24, 18);
  line-height: 125%;
  display: flex;
  gap: toRem(5);
  align-items: center;
  color: #ffffff;
  @media (max-width: 832px) {
    text-align: center;
    gap: 0px !important;
    flex-direction: column;
    justify-content: center;
  }
}
.sub-title {
  font-weight: 400;

  @include adaptiveValue("font-size", 30, 21);
  color: #ffffff;
}
.text {
  font-weight: 300;
  @include adaptiveValue("font-size", 20, 16);
  line-height: 176%;
  color: rgba(255, 255, 255, 0.7);
}
.text-whitepaper {
  font-weight: 300;
  @include adaptiveValue("font-size", 24, 16);
  line-height: 176%;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: toRem(20);
}
.text-white {
  font-weight: 300;
  @include adaptiveValue("font-size", 20, 16);
  line-height: 176%;
  color: white;
}
.button {
  border-radius: 15px;
  padding: 13px 25px;
  font-weight: 800;
  @include adaptiveValue("font-size", 25, 18);
  color: #0b0b0f;
  background: $btnColor;
  text-transform: capitalize;
  transition: background 0.5 ease;

  @media (min-width: $mobile) {
    &:hover {
      background: $hoverColor;
    }
  }
}

.block-bg {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  @include adaptiveValue("padding", 40, 15);
  background: #000;
  border-radius: 20px;
  margin-bottom: toRem(30);
}
.block-bg-box {
  @include adaptiveValue("margin-bottom", 70, 30);
}

/////////
