@import "../../../variable/variable.scss";




.finanse {
  overflow: visible;
  &__container{
    
  }
  &__title {
  }
  &__bg-line {
    position: relative;
    margin-top: toRem(80);
   width: 140%;
   margin: 0 auto;
   transform: translate(-30%,0);
    height: 110px;
    padding: 30px 0;
// &::before{
//   content:'';
//   position: absolute;
//   background: url("../../../img/bgLine.svg") 0 0/100% 100% no-repeat;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   left: -30%;
// }

   
  }

  &__list {
    margin-top: toRem(100);
    margin-bottom: toRem(100);
  }

  &__item {
    @include adaptiveValue("padding-left", 130, 20);
    @include adaptiveValue("padding-right", 130, 20);
    margin-left: 15px;
    

color: white;
position: relative;
z-index: 2;
color: #000;
&::before{

  content:none ;
  
}


  }}

.components-finanse {
  justify-content: center;
  display: flex;
  position: relative;
  margin: 0 auto;
@include adaptiveValue("margin-bottom",290 ,170 );
@include adaptiveValue("width",1100 ,600,0,1300 );
height: 670px;
@media (max-width:670px){
    height: 1200px;
    margin-bottom: 40px;
}

background: url('../../../img/logo3d.svg') -100px 0/100% 100% no-repeat;
//  @media (max-width:1185px){
//     background: url('../../../img/Img_Logo3D.svg') 0 0/100% 100% no-repeat;
//  }
@media (max-width:1185px){
  background: url('../../../img/logo3DMob.svg') -140px 0/100% 100% no-repeat; 
  
}
@media (max-width:670px){
  background: url('../../../img/logomob-ww.svg')100px 0 no-repeat ; 
}
//  @media (max-width:$mobileSmall){
//    background: url('../../../img/logoSmal.svg') -140px 0/100% 100% no-repeat; 
    
//  }
@media (max-width:490px){
  background: url('../../../img/logomob-ww.svg')-20px 0 no-repeat ; 
}
}
.text-box-finanse {
  
  @include adaptiveValue("width", 314,200 );
  position: absolute;
 
  &__title {
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    span {
      color: $btnColor;
    }
  }

  &__sub-title {
    font-weight: 300;
    font-size: 15px;
    @include adaptiveValue("font-size",15 ,13 );
    color: rgba(255, 255, 255, 0.5);
  }
}

.components-finanse {
  
  
  &__text-box {
    &_1 {
      left: 0%;
      top: 5%;
     @media (max-width:1185px){
        left: 50%;
        top: -1%;
     }
     @media (max-width:670px){
        left: 10%;
        top: 87%;
     }
     @media (max-width:490px){
        left: 2%;
     }
    }

    &_2 {
      right: -10%;
      top: 2%;
      @include adaptiveValue("right",0 ,140,0,1000);
       @media (max-width:1185px){
        right: 15%;
        top: 22%;
     }
     @media (max-width:670px){
        left: 10%;
        top: 30%;
     }
      @media (max-width:490px){
        left: 2%;
     }
    }

    &_3 {
      left: -3%;
      bottom: 16%;
      @include adaptiveValue("left",-40 ,50 );
       @media (max-width:1185px){
        left: 40%;
        bottom: 13%;
     }
     @media (max-width:670px){
        left: 10%;
        bottom: 36%;
     }
     @media (max-width:490px){
        left: 2%;
     }
    }

    &_4 {
      bottom: -21%;
      left: 50%;
      @media (max-width:1185px){
        left: 30%;
     }
     @media (max-width:670px){
        left: 10%;
        bottom: 19%;
     }
     @media (max-width:490px){
        left: 2%;
     }
     @media (max-width:414px){
        bottom: 17%;
        
     }
    }

    &_5 {
      
      top: 50%;
      @include adaptiveValue("right",-150 ,200,0,1500);
 @media (max-width:1185px){
        right: 15%;
     }
     @media (max-width:670px){
        left: 10%;
        top: 42%;
     }
     @media (max-width:490px){
        left: 2%;
     }
    }
  }
}
.progres{
  .img{
position: relative;
    z-index: 3;
    &:not(:last-child){
      &::after{
      content:'';
      width: 0px;
      height: 100%;
      border:dotted 1px rgba(254, 254, 254, 0.496);
     
      position: absolute;
      left: -6px;
      top: 120px;
      z-index: -1;
    }}
    &:nth-child(1){
      &::before{
        content:'1';
        
      }
    }
    &:nth-child(2){
      &::before{
        content:'2';
        background: $btnColor;
      }
    }
    &:nth-child(3){
      &::before{
        content:'3';
        
      }
    }
    &::before {
      content: "1";
  display: flex;
  justify-content: center;
  align-items: center;
  width: toRem(40);
  font-size: 16px;
  font-weight: 700;
  height: toRem(40);
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  left: -25px;
  top: 80px;
  z-index: -1;
  
}
}

}

