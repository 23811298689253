@import '../../variable/variable.scss';
.whitepaper {
  overflow: hidden;
  background: url('../../../src/img/WhitepaperPc.jpg') 0 0/100% 100% no-repeat ;
  @media (max-width:$tablet){
  background: url('../../../src/img/Whitepaper\ Ta.jpg') 0 -100px/100% no-repeat ;
      
  }
  @media (max-width:700px){
  background: url('../../../src/img/WhitepaperMo.jpg') 0 -100px/100%  105% no-repeat rgb(11,11,15) !important;
  }
  
  .title {
    margin-bottom: toRem(30);
  }
  .text {
    margin-bottom: toRem(30);
  }
  &__container {
    max-width: 1100px;
    overflow: hidden;
  }
}
#whitepaper{
  overflow: visible;
}