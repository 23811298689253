@import "/src/variable/variable.scss";

[class*="__container"] {
  @if ($maxWidth > 0) {
    max-width: 1396px;
    margin: 0 auto;
    padding: 0 15px;
    overflow: visible !important;
    
  }
}
// .loga-animate{
 
//   top: 0;
//   right: 0;
//   z-index: -1;
//      @include adaptiveValue("width",530 ,325 );
//      @include adaptiveValue("height",530 ,325 );

// }
.App {
  margin: 0 auto;
 

  


  @media (max-width: $mobile) {
    background-color: #0b0c0f;
    // background: url("../src/img/bgmob.png") 0px 0px /100% 50% repeat-y;
  }

  min-height: 100%;
  display: flex;
  flex-direction: column;

  @supports (overflow: clip) {
    overflow: clip;
  }
  /* //Притискаємо footer */
  > main {
    flex: 1 1 auto;
  }
  /* // Фікс для слайдерів */
  > * {
    min-width: 0;
  }
}
html {
  scroll-behavior: smooth;
}
body {
  /* font-family: $fontFamily;
  color: $mainColor;
  font-size: $fontSize; */
}

/* // Обнулення стилів */
* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
*::before,
*::after {
  display: inline-block;
}
:focus,
:active {
  /* // outline: none; */
}
a:focus,
a:active {
  /* // outline: none; */
}
html,
body {
  height: 100%;
}
body {
  /* // color: rgba(255, 255, 255, 1); */
  /* // line-height: 1; */
  font-family: "Kumbh Sans";
  /* // font-size: 16px; */
  /* //text-rendering: optimizeLegibility; */
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input,
button,
textarea {
  /* // font-family:'Courier New', Courier, monospace;
  font-size: inherit;
  line-height: inherit; */
}
button {
  cursor: pointer;
  color: inherit;
  background-color: transparent;
}
a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}
