@import '../../../variable/variable.scss';
.overview {
  @include adaptiveValue("padding-bottom",200 ,40 );

		.title-mini{
      gap: 20px;
    }

		&__list {
   
      margin-top: toRem(70);
		}
    
    
    
		&__item {
      @include adaptiveValue("padding-left",130 ,20 );
      @include adaptiveValue("padding-right",130 ,20 );
      margin-left: 15px;
      
      position: relative;
      color: white;
      position: relative;
      z-index: 2;
      color: #000;
      
     
		}
}
.progres{
  
  .img{
    position: relative;
    z-index: 3;
    &:not(:last-child){
      &::after{
      content:'';
      width: 0px;
      height: 100%;
      border:dotted 1px rgba(254, 254, 254, 0.496);
     
      position: absolute;
      left: -6px;
      top: 120px;
      z-index: -1;
    }
    }
    
    &:nth-child(1){
      &::before{
        content:'1';
        
      }
    }
    &:nth-child(2){
      &::before{
        content:'2';
        background: $btnColor;
      }
    }
    &:nth-child(3){
      &::before{
        content:'3';
        
      }
    }
     &:nth-child(4){
      &::before{
        content:'4';
        background: $btnColor;
        
      }
    }
    &:nth-child(5){
      &::before{
        content:'5';
      }
    }
    &:nth-child(6){
      &::before{
        content:'6';
        background: $btnColor;
        
      }
    }
    &:nth-child(7){
      &::before{
        content:'7';
        
      }
    }
    &::before {
      content: "1";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  width: toRem(40);
  height: toRem(40);
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  left: -25px;
  top: 90px;
  z-index: -1;
  
}
}

}