@import '../../variable/variable.scss';
.roadmap {
  position: relative;
   &::after{
      content:'';
      background: url('../../img/dec3.png') 0 0/100% 100% no-repeat;
    @include adaptiveValue("width",415,208);
    @include adaptiveValue("height", 404, 233);
    position: absolute;
    top: -60px;
    
   @include adaptiveValue("right", -250, 0);

    }
   max-width: 1396px; 
  // overflow: hidden;
  margin: 0 auto;
  width: 100%;
  &__title {
    margin-bottom: 40px;
    @include adaptiveValue("margin-bottom",100 ,40 );
  }

  &__list {
    display: flex;
    gap: 20px;
    
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__item {
    min-width: toRem(414);
height: toRem(373);
  }
}

.roadmap-item {
  position: relative;
  z-index: 1;
  background: #000;
  padding: 35px;
  display: flex;
  padding-bottom: 45px;
  flex-direction: column;
  justify-content: center;
  height: max-content;
  // max-width: 414px;
  border-radius: 15px;
  align-items: center;
  gap: 20px;

  &__title {
    margin-bottom: 50px;
   
  }

  &__text {
    max-width: 270px;
    @include adaptiveValue("font-size", 24, 16);
    span {
      color: white;
    }
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }
}
