
@import '../../../variable/variable.scss';
.nav-list {
@include adaptiveValue("padding-top",200 ,80 );
		@include adaptiveValue("padding-bottom",300 ,390 );

		&__sub-title {
      @include adaptiveValue("font-size",40 ,22 );
		}


		&__list {
			position: relative;
			z-index: 1;
			@media (max-width:$tablet){
				&::before{
					content:'';
					width: 541px;
					position: absolute;
					height: 571px;
					background: url('../../../img/logo-tab-w.png') 0 0/100% 100% no-repeat;
					z-index: 0;
					bottom: -380px;
					left: 50%;
					transform: translate(-50%,0%);
				}
			}
			@media (max-width:700px){
				  
				&::before{
					content:'';
					width: 371px;
					position: absolute;
					height: 391px;
					background: url('../../../img/logo-mob-w.png') 0 0/100% 100% no-repeat;
					z-index: 0;
					bottom: -380px;
					left: 50%;
					transform: translate(-50%,0%);
				}
			}
    }
    
		// .nav-list__item
    
		&__item {
			z-index: 2;
			position: relative;
      color: rgba(255, 255, 255, 0.7);

      font-weight: 300;
@include adaptiveValue("font-size",24 ,18 );
line-height: 1.87;

span{
  color: $btnColor;
  opacity: 1;
}
		}
}

