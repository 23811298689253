.modal{
  width: 1900px;
  height: 100vh;
position: absolute;
overflow: hidden;
  margin: 0 auto;
  
  background: transparent;
  
}
.modal::backdrop {
  
  backdrop-filter: blur(5px);
}