@import "../../../variable/variable.scss";

.links {
  &__title-mini {
    text-align: center;
    display: flex ;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: toRem(40);
    flex-direction: row
    
  }

  &__social {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    align-items: center;
  }

  &__discord {
  }

  &__telegram {
  }

  &__twitter {
  }
}
